.navWrapper {
  height: 72px;
}
.pageNav {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 16px 0;
  width: 100%;
}
.navButton {
  display: flex;
  align-items: flex-end;
}
.saveLink {
  margin-right: auto;
}
.backButton {
  margin-right: 16px;
}
.saveButton {
  min-width: 160px;
}
.centerButton {
  margin: 1rem auto;
}
@media only screen and (max-width: 959.95px) {
  .navWrapper {
    height: auto;
  }
  .pageNav {
    position: relative;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 599.95px) {
  .backButton {
    display: none;
  }
  .forwardButton {
    width: 100%;
    min-height: 50px;
  }
  .navContainer, .navButton {
    flex-direction: column-reverse;
    width: 100%;
    align-items: stretch;
  }
  .saveLink {
    margin-top: 20px;
    align-items: stretch;
  }
}
